import React from 'react'
import fetch from 'isomorphic-fetch'
import { ApolloProvider } from 'react-apollo'
import { ApolloClient, InMemoryCache, HttpLink, DefaultOptions } from 'apollo-boost'

const cache = new InMemoryCache()
const link = new HttpLink({
  uri: `${process.env.GATSBY_API_URL}/graphql`,
  fetch: fetch,
})

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

const client = new ApolloClient({
  cache,
  link,
  defaultOptions,
})

export const ApolloWrapper = ({ children }) => <ApolloProvider client={client}>{children}</ApolloProvider>
export default client

// to fix some issues I had during gatsby ssr, I followed this: https://github.com/gatsbyjs/gatsby/issues/11225#issuecomment-457211628
