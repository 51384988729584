import { InvoiceDocumentData } from '../util/api/types'
import ISummary from '../components/Wrike/Summary/ISummary'
import UserType from '../types/User'

function createLocalStorage<T>(name: string, defaultValue: T) {
  const isBrowser = typeof window !== 'undefined'

  const storage = {
    get: () => (isBrowser ? window.localStorage.getItem(name) || defaultValue : defaultValue) as T,
    set: (value: string) => window.localStorage.setItem(name, value),
    clear: () => window.localStorage.removeItem(name),
  }

  return storage
}

const numberToCurrencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

const summaryToDocumentConverter = (userInfo: UserType, data: ISummary): InvoiceDocumentData => {
  const getCost = (hours: number) => numberToCurrencyFormatter.format(userInfo.paymentRate * hours)
  return {
    userInfo: {
      name: userInfo.fullName,
      address: userInfo.address,
      email: userInfo.email,
      paymentRate: numberToCurrencyFormatter.format(userInfo.paymentRate),
      invoiceDate: data.date,
      totalBalance: getCost(data.total),
      totalHours: data.total.toFixed(2),
    },
    projectDetails: data.projects.map((p) => ({
      projectName: p.title,
      totalHours: p.total.toFixed(2),
      totalCost: getCost(p.total),
      categories: p.categories.map((cat) => ({
        categoryName: cat.name,
        hours: cat.total.toFixed(2),
        tasks: cat.tasks.map((task) => ({
          taskName: task.title,
          hours: task.total.toFixed(2),
          times: task.times.map((time) => ({
            description: time.description,
            hours: time.time.toFixed(2),
          })),
        })),
      })),
    })),
    projectSummary: {
      projects: data.projects.map((p) => ({ name: p.title, hours: p.total.toFixed(2), cost: getCost(p.total) })),
      paymentInformation: userInfo.paymentMethod,
    },
  }
}

export { createLocalStorage, summaryToDocumentConverter, numberToCurrencyFormatter }
