import React from 'react'
import { ThemeType } from '../layout/themes'

export default interface UserType {
  id: number
  // avatar: File
  fullName: string
  email: string
  wrikeUserId?: string
  paymentRate?: number
  address?: string
  paymentMethod?: string
  created_at: string
}

export enum AuthActionTypes {
  LOGIN = '@@auth/LOGIN',
  LOGOUT = '@@auth/LOGOUT',
  SET_THEME = '@@auth/SETTHEME',
  SET_EXTRA = '@@auth/EXTRA',
}

export interface AuthPayloadType {
  type: string
  payload?: AuthStateType
}
export interface AuthContextType {
  authState: AuthStateType
  dispatch: React.Dispatch<AuthPayloadType>
}

export interface AuthStateType {
  readonly jwt?: string
  readonly user?: UserType
  readonly theme?: ThemeType
  readonly loggedIn?: boolean
  readonly extra?
}
