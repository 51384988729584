// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-auth-email-sent-tsx": () => import("./../../../src/pages/auth/email-sent.tsx" /* webpackChunkName: "component---src-pages-auth-email-sent-tsx" */),
  "component---src-pages-auth-index-tsx": () => import("./../../../src/pages/auth/index.tsx" /* webpackChunkName: "component---src-pages-auth-index-tsx" */),
  "component---src-pages-auth-logout-tsx": () => import("./../../../src/pages/auth/logout.tsx" /* webpackChunkName: "component---src-pages-auth-logout-tsx" */),
  "component---src-pages-auth-register-tsx": () => import("./../../../src/pages/auth/register.tsx" /* webpackChunkName: "component---src-pages-auth-register-tsx" */),
  "component---src-pages-auth-request-password-tsx": () => import("./../../../src/pages/auth/request-password.tsx" /* webpackChunkName: "component---src-pages-auth-request-password-tsx" */),
  "component---src-pages-auth-reset-password-tsx": () => import("./../../../src/pages/auth/reset-password.tsx" /* webpackChunkName: "component---src-pages-auth-reset-password-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-wrike-auth-tsx": () => import("./../../../src/pages/wrike-auth.tsx" /* webpackChunkName: "component---src-pages-wrike-auth-tsx" */)
}

