import React from 'react'
import * as api from './api'

import { AuthStateType, AuthActionTypes, AuthPayloadType, AuthContextType } from '../types/User'
import { ThemeType } from '../layout/themes'
import { createLocalStorage } from './helpers'

const authStorage = createLocalStorage('auth', '')
const themeStorage = createLocalStorage<ThemeType>('theme', 'default')

const DEFAULT_STATE: AuthStateType = {
  jwt: null,
  user: null,
  loggedIn: false,
  theme: 'default',
  extra: {},
}

const reducer = (state: AuthStateType, action: AuthPayloadType) => {
  switch (action.type) {
    case AuthActionTypes.SET_EXTRA:
      const { extra } = action.payload
      return { ...state, extra } as AuthStateType
    case AuthActionTypes.SET_THEME:
      const { theme } = action.payload
      return { ...state, theme } as AuthStateType
    case AuthActionTypes.LOGIN:
      const { jwt, user } = action.payload
      return { ...state, jwt, user, loggedIn: true } as AuthStateType
    case AuthActionTypes.LOGOUT:
      return { jwt: null, user: null, loggedIn: false } as AuthStateType
    default:
      return DEFAULT_STATE
  }
}

const AuthContext = React.createContext<AuthContextType>(null)

const AuthProvider = ({ children }) => {
  const [authState, dispatch] = React.useReducer(reducer, DEFAULT_STATE)
  return <AuthContext.Provider value={{ authState, dispatch }}>{children}</AuthContext.Provider>
}

const wrapRootElement = ({ element }) => <AuthProvider>{element}</AuthProvider>

const useAuth = () => {
  const { authState, dispatch } = React.useContext(AuthContext)
  const isAuthenticated = authState.loggedIn && Object.keys(authState.user).length

  const login = async (credentials) => {
    const { data } = await api.signup(credentials)

    authStorage.set(data.jwt)
    dispatch({ type: AuthActionTypes.LOGIN, payload: data })

    return data
  }

  const logout = () => {
    authStorage.clear()
    dispatch({ type: AuthActionTypes.LOGOUT })
  }

  const getUser = async () => {
    // authStorage.clear()
    const theme = themeStorage.get()
    dispatch({ type: AuthActionTypes.SET_THEME, payload: { theme } })

    const token = authStorage.get()
    if (!token) throw 'Token'

    const { data } = await api.getUser(token)
    const payload = { user: data, jwt: token, loggedIn: true }
    dispatch({ type: AuthActionTypes.LOGIN, payload })
    return payload
  }

  const dispatchExtra = async (extraData) => {
    dispatch({ type: AuthActionTypes.SET_EXTRA, payload: { extra: { ...authState.extra, ...extraData } } })
  }

  const setTheme = (theme: ThemeType) => {
    themeStorage.set(theme)
    dispatch({ type: AuthActionTypes.SET_THEME, payload: { theme } })
  }

  return { authState, isAuthenticated, login, logout, getUser, setTheme, dispatchExtra }
}

export { wrapRootElement, AuthProvider, authStorage }
export default useAuth
