/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react'
import { AuthProvider } from './src/util/useAuth'
import { ApolloWrapper } from './src/util/graphql'

export const wrapRootElement = ({ element }) => (
  <AuthProvider>
    <ApolloWrapper>{element}</ApolloWrapper>
  </AuthProvider>
)
