import axios from 'axios'
import { CredentialProps, RegisterProps, ResetDataProps, SendEmailProps, WrikeSummaryProps, WrikeTotalsProps, InvoiceDocumentData } from './types'

const API_URL = process.env.GATSBY_API_URL
const WRIKE_CLIENT_ID = process.env.GATSBY_WRIKE_CLIENT_ID
const getHeaders = (token: string, headers = {}) => ({ headers: { ...headers, Authorization: `Bearer ${token}` } })

export const getStrapiError = (e) => (e.response.data?.message?.length ? e.response.data.message[0].messages[0].message : e.response.statusText)
export const signup = async (credentials: CredentialProps) => await axios.post(`${API_URL}/auth/local`, credentials)
export const register = async (data: RegisterProps) => await axios.post(`${API_URL}/auth/local/register`, data)
export const forgot = async (email: string) => await axios.post(`${API_URL}/auth/forgot-password`, { email })
export const reset = async (data: ResetDataProps) => await axios.post(`${API_URL}/auth/reset-password`, data)
export const getUser = async (token: string) => await axios.get(`${API_URL}/users/me`, getHeaders(token))

export const setUserProfile = async (token: string, data, avatar?: File) => {
  const formData = new FormData()
  formData.append('data', JSON.stringify(data))
  if (avatar) formData.append('files.avatar', avatar)
  return await axios.put(`${API_URL}/users/me`, formData, getHeaders(token, { 'content-type': 'multipart/form-data' }))
}

export const sendEmail = async (token: string, data: SendEmailProps) => await axios.post(`${API_URL}/email`, data, getHeaders(token))

export const wrikeGetCodeUrl = `https://www.wrike.com/oauth2/authorize/v4?client_id=${WRIKE_CLIENT_ID}&response_type=code`
export const wrikeConnect = async (token: string, code: string) => await axios.post(`${API_URL}/wrike/connect`, { code }, getHeaders(token))
export const wrikeGetContact = async (token: string) => await axios.get(`${API_URL}/wrike/contact`, getHeaders(token))
export const wrikeGetSummary = async (token: string, data: WrikeSummaryProps) => await axios.post(`${API_URL}/wrike/summary`, data, getHeaders(token))
export const timeCampGetSummary = async (token: string, data: WrikeSummaryProps) => await axios.post(`${API_URL}/timecamp/summary`, data, getHeaders(token))
export const wrikeGetTotals = async (token: string, data: WrikeTotalsProps) => await axios.post(`${API_URL}/wrike/totals`, data, getHeaders(token))
export const timeCampGetTotals = async (token: string, data: WrikeTotalsProps) => await axios.post(`${API_URL}/timecamp/totals`, data, getHeaders(token))
export const wrikeGeneratePDF = async (token: string, data: InvoiceDocumentData) =>
  await axios.post(`${API_URL}/wrike/generatepdf`, data, { ...getHeaders(token), responseType: 'blob' })
export const wrikeSendEmailInvoice = async (token: string, data: InvoiceDocumentData) =>
  await axios.post(`${API_URL}/wrike/sendemailinvoice`, data, getHeaders(token))
export const timeCampGeneratePDF = async (token: string, data: InvoiceDocumentData) =>
  await axios.post(`${API_URL}/timecamp/generatepdf`, data, { ...getHeaders(token), responseType: 'blob' })
export const timeCampSendEmailInvoice = async (token: string, data: InvoiceDocumentData) =>
  await axios.post(`${API_URL}/timecamp/sendemailinvoice`, data, getHeaders(token))
